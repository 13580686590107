const {VUE_APP_API_URL} = process.env;

export const config = {
  apiBaseUrl: `${VUE_APP_API_URL}`,
  graphqlUrl: `${VUE_APP_API_URL}/graphql`,
  graphqlUrlPublic: `${VUE_APP_API_URL}/public/graphql`,
};

export const page_size = 100;
export const fuzziness = 1;

const {VUE_APP_SENTRY_DSN, VUE_APP_ENV_NAME, VUE_APP_ENV} = process.env;

export const sentry = {
  dsn: VUE_APP_SENTRY_DSN,
  environment: VUE_APP_ENV_NAME ?? VUE_APP_ENV,
};

const {
  VUE_APP_PADDLE_ENVIRONMENT = 'production',
  VUE_APP_PADDLE_TOKEN,
} = process.env;

export const paddle = {
  environment: VUE_APP_PADDLE_ENVIRONMENT,
  token: VUE_APP_PADDLE_TOKEN,
};

const {
  VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
  VUE_APP_GOOGLE_TAG_MANAGER_PROPERTY_ID,
} = process.env;

export const google = {
  analytics: {
    appName: 'EvidenceHunt',
    config: {
      id: VUE_APP_GOOGLE_ANALYTICS_PROPERTY_ID,
    },
  },
  tagManager: {
    id: VUE_APP_GOOGLE_TAG_MANAGER_PROPERTY_ID,
  },
};