<template>
  <v-app-bar v-if="!mobile" color="bg" class="nav" :height="54" elevation="0">
    <template #prepend>
      <div :class="{ 'logo-mdUp-Wrapper': mdAndUp, 'logo-wrapper': true }">
        <RouterLink :to="{ name: 'Chat' }">
          <v-app-bar-title>
            <inline-svg :src="LogoIcon" aria-label="logo" />
          </v-app-bar-title>
        </RouterLink>
        <span v-if="isPro" style="color: grey; margin-bottom: 10px">Pro</span>
      </div>
    </template>

    <template #append>
      <div class="nav__center">
        <HeaderTabs :user="userProfile" />
      </div>
      <div class="nav__controls">
        <v-slide-x-transition>
          <UpgradeButton v-if="!isPayingUser && isLoggedIn && !isSuperAdmin && mdAndUp"
        /></v-slide-x-transition>

        <UserMenu v-if="isLoggedIn" />

        <BaseButton
          v-if="!userProfile?.uid"
          :disabled="isUserLoading"
          @click="onLoginClick"
          to="/login">
          {{ t('global.login') }}
        </BaseButton>
      </div>
    </template>
  </v-app-bar>
  <MobileHeader v-else />
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import HeaderTabs from '@/features/Header/components/HeaderTabs';
import BaseButton from '@/components/BaseButton.vue';
import LogoIcon from '@/assets/icons/logo-spear.svg';
import MobileHeader from '@/components/layout/MobileHeader.vue';
import UpgradeButton from '@/features/Header/components/UpgradeButton.vue';
import UserMenu from '@/features/Header/components/UserMenu.vue';

const { t } = useI18n();
const { mobile, mdAndUp } = useDisplay();

const drawer = ref(false);

const store = useStore();
const router = useRouter();

const isPro = computed(() => store.getters.isPro);
const isSuperAdmin = computed(() => store.getters.isSuperAdmin);
const isLoggedIn = computed(() => store.getters.isLoggedIn);
const userProfile = computed(() => store.state.userProfile);
const isUserLoading = computed(() => store.state.isUserLoading);
const isPayingUser = computed(() => store.getters.isPaying);

const onLoginClick = () => {
  const pathContainsChatHistory = router.currentRoute.value.path.includes('/chatHistory/');
  if (pathContainsChatHistory) {
    localStorage.setItem('authRedirectedUrl', router.currentRoute.value.path);
  } else {
    localStorage.setItem('authRedirectedUrl', '/');
  }
};

watch(mobile, (newVal) => {
  if (!newVal) drawer.value = newVal;
});
</script>

<style lang="scss" scoped>
.p-menu-item {
  font-family: 'Red Hat Display', 'Nunito', 'Charter', Arial, sans-serif;
  font-weight: 600;
  color: rgb(var(--v-theme-primary));
  font-size: (14px);
}
.nav {
  padding: 0 rem(24px);
  border-bottom: 1px solid rgb(var(--v-theme-secondary));
  &__center {
    display: flex;
    align-items: center;
    text-transform: capitalize;
    flex-grow: 1;
    justify-content: center;
    :deep() {
      .v-btn {
        text-transform: capitalize;
      }
    }
  }
  &__controls {
    display: flex;
    align-items: center;
  }
}
:deep() {
  .v-toolbar-title {
    &__placeholder {
      display: flex;
    }
  }
  .v-toolbar {
    &__content {
      gap: rem(12px);
    }
    &__prepend {
      margin: 0;
    }
    &__append {
      margin-right: 0;
      flex-grow: 1;
      justify-content: flex-end;
    }
  }
}
.unfold-btn {
  text-transform: none;
}
.logo-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  height: 100%;
}
.logo-mdUp-Wrapper {
  //The total of the Logo(user menu button) and upgrade button's width
  width: calc(172px + rem(120px));
}
</style>
