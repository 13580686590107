<template>
  <v-select
    class="base-select w-100"
    variant="outlined"
    flat
    rounded="lg"
    density="compact"
    hide-details
    menu-icon="mdi-chevron-down"
    item-color="primary"
    item-title="label"
    item-value="value"
    :menu-props="{ maxWidth: '100%' }"
    v-model="selectedAccount"
    :items="options">
    <template #item="{ props, item }">
      <v-list-item class="base-select__item" v-bind="props" :data-value="item.value">
        <template #prepend>
          <v-icon
            v-if="item.value !== 'personal'"
            :icon="'mdi-office-building'"
            style="margin-right: -24px"
            size="x-small"></v-icon>
          <v-avatar v-else color="grey" style="margin-right: -8px; height: 1em; width: 1em">
            <span class="user-menu-inner-initials">{{ initials }}</span>
          </v-avatar>
          <v-list-item-title> {{ item.label }}</v-list-item-title>
        </template>
      </v-list-item>
    </template>
    <template #selection>
      <v-list-item-title class="account-switcher-label">
        {{
          selectedAccount === 'personal'
            ? t('userMenu.personal')
            : organizationItems.find((org) => org.id === activeOrganizationId)?.name
        }}
      </v-list-item-title>
    </template>
  </v-select>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useUserProfile } from '@/composables/useUserProfile';

const store = useStore();
const router = useRouter();
const { t } = useI18n();
const { initials } = useUserProfile();
const displayName = computed(() => store.getters.displayName);

const organizationItems = computed(() => store.state.organizations);
const activeOrganizationId = computed(() => store.state.organization?.id);
const activeUserUid = computed(() => !store.state.organization && store.state.userProfile?.uid);

const selectedAccount = computed({
  get: () => (activeUserUid.value ? 'personal' : activeOrganizationId),
  set: (value) => {
    if (value === 'personal') {
      if (activeUserUid.value) {
        return;
      }
      selectUser();
    } else {
      if (activeOrganizationId.value === value) {
        return;
      }
      selectOrganization(value);
    }
  },
});

const options = computed(() => {
  const orgOptions = (organizationItems.value || []).map((org) => ({
    label: org.name,
    value: org.id,
  }));
  return [
    ...orgOptions,
    {
      label: displayName.value,
      value: 'personal',
    },
  ];
});

const selectOrganization = (organizationId) => {
  store.dispatch('selectOrganizationId', { organizationId });
  router.push('/');
  store.dispatch('newChat');
};

const selectUser = () => {
  store.dispatch('selectUser');
  router.push('/');
  store.dispatch('newChat');
};
</script>

<style lang="scss" scoped>
.base-select {
  margin-top: -8px;
  margin-bottom: -8px;

  &.v-select--active-menu {
    :deep() {
      .v-field {
        &__clearable {
          display: flex;
        }
      }
    }
  }
  :deep() {
    .v-select {
      &__menu-icon {
        margin: 0;
      }
      &__selection,
      &__selection > * {
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
      }
    }
    .v-field {
      height: rem(32px);
      padding-right: 0px;
      &__outline {
        border: none;
        color: transparent;
      }
      &__input {
        padding-left: 0px;
        padding-right: 0px;
      }
      &__clearable {
        display: none;
        margin: 0;
      }
    }
    .v-field__field {
      height: 100%;
    }
    .v-field__append-inner {
      opacity: var(--v-medium-emphasis-opacity);
    }
    .v-field__input {
      min-height: unset;
      height: 100%;
      font-size: rem(12px);
      font-weight: 500;
    }
  }
  &__item {
    :deep() {
      min-height: unset;
      .v-list-item-title {
        font-size: rem(14px);
      }
      &.v-list-item {
        &--active {
          .v-list-item-title {
            color: rgb(var(--v-theme-text));
            font-weight: 500;
          }
        }
      }
    }
  }
}
.user-menu-inner-initials {
  font-size: rem(6px);
}
.account-switcher-label {
  @include textSubtitle;
}
</style>
