import { createRouter, createWebHistory } from 'vue-router';

import { toast } from 'vue3-toastify';
import { auth } from '../lib/firebase';

var routes = [
  {
    path: '/browse',
    name: 'Search',
    component: () => import('../views/Browse.vue'),
    meta: { title: 'Browse Page' },
    props(route) {
      return { qid: route.query.qid, rid: route.query.rid };
    },
  },
  {
    path: '/',
    name: 'Chat',
    component: () => import('../views/Chat.vue'),
    meta: { requiresAuth: false, title: 'Chat with protocols and abstracts' },
    props(route) {
      return { sid: route.query.sid };
    },
  },
  {
    path: '/knowledge-base',
    children: [
      {
        path: '',
        name: 'Knowledge Base',
        component: () => import('../views/KnowledgeBase.vue'),
        meta: { title: 'Knowledge Base' },
      },
      {
        path: ':id',
        name: 'Knowledge Base Article',
        component: () => import('../views/KnowledgeBaseArticle.vue'),
        meta: { title: 'Knowledge Base Article' },
      },
    ],
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About.vue'),
    meta: { title: 'About Page' },
  },
  {
    path: '/userprofile',
    name: 'UserProfile',
    component: () => import('../views/UserProfile.vue'),
    meta: { requiresAuth: true, title: 'User Profile Page' },
  },
  /*{
    path: '/updates',
    name: 'Updates',
    component: () => import('../views/Updates.vue'),
    meta: { title: 'Updates Page' },
  },*/
  /*{
    path: '/library',
    name: 'Library',
    component: () => import('../views/Library.vue'),
    meta: { requiresAuth: true, title: 'Library Page' },
  },*/
  {
    path: '/terms',
    name: 'Terms',
    component: () => import('../views/Terms.vue'),
    meta: { title: 'Terms of Use' },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import('../views/Privacy.vue'),
    meta: { title: 'Privacy Policy' },
  },
  {
    path: '/auth-action',
    name: 'Auth Action',
    meta: { title: 'Auth Action' },
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: { title: 'Login Page', sideBanner: true },
    beforeEnter: (to, from, next) => {
      if (
        auth.onAuthStateChanged((user) => {
          if (user) {
            next({ path: '/' });
          } else {
            next();
          }
        })
      );
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('../views/Register.vue'),
    meta: { title: 'Register Page', sideBanner: true },
  },
  {
    path: '/reset',
    name: 'Reset',
    component: () => import('../views/Reset.vue'),
    meta: { title: 'Reset Page', sideBanner: true },
  },
  {
    path: '/verification',
    name: 'Verification',
    component: () => import('../views/Verification.vue'),
    meta: { title: 'Verification Email Page', sideBanner: true },
  },
  {
    path: '/customAuthAction',
    name: 'customAuthAction',
    component: () => import('../views/CustomAuthAction.vue'),
    meta: { title: 'Auth Action Page', sideBanner: true },
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import('../views/Logout.vue'),
    meta: { title: 'Logout Page', requiresAuth: true },
  },
  {
    path: '/unsubscribe',
    name: 'Unsubscribe',
    component: () => import('../views/Unsubscribe.vue'),
    meta: { title: 'Unsubscribe Page', requiresAuth: true },
  },
  {
    path: '/unsubscribed',
    name: 'Unsubscribed',
    component: () => import('../views/Unsubscribed.vue'),
    meta: { title: 'Unsubscribed Page', requiresAuth: true },
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('../views/Pricing.vue'),
    meta: { title: 'Pricing Page' },
  },
  {
    path: '/organizations',
    name: 'Organizations',
    component: () => import('../views/Organizations.vue'),
    meta: { title: 'Organizations', requiresAuth: true, requiresSuperAdmin: true },
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/Users.vue'),
    meta: { title: 'Users', requiresAuth: true, requiresSuperAdmin: true },
  },
  {
    path: '/organization',
    children: [
      {
        path: ':id',
        name: 'OrganizationSettings',
        component: () => import('../views/OrganizationSettings.vue'),
        meta: { title: 'Organization settings', requiresAuth: true, requiresOrganizationId: true },
      },
    ],
  },
  /*{
    path: '/unauthorized',
    name: 'Unauthorized',
    component: () => import('../views/Unauthorized.vue'),
    meta: { title: 'Unauthorized' },
  },*/
];

export default function createAppRouter(store, i18n) {
  const router = createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes,
  });

  const checkAuthorization = (condition, next) => {
    const isSuperAdmin = store.state.userProfile?.superAdmin;

    if (condition || isSuperAdmin) {
      next();
    } else {
      next({ path: '/' });
      setTimeout(
        () => toast.error(i18n.global.t('unauthorized.subtitle'), { autoClose: 5000 }),
        500
      );
    }
  };

  const checkOrganizationId = (to, next) => {
    const condition =
      store.state.organization &&
      store.state.organization.id &&
      store.state.organization.id === to.params.id;
    checkAuthorization(condition, next);
  };

  const checkSuperAdmin = (next) => {
    const condition = store.state.userProfile?.superAdmin;
    checkAuthorization(condition, next);
  };
  const checkProperAuthorization = (to, next) => {
    if (to.matched.some((record) => record.meta.requiresOrganizationId)) {
      checkOrganizationId(to, next);
    } else if (to.matched.some((record) => record.meta.requiresSuperAdmin)) {
      checkSuperAdmin(next);
    }
  };

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      auth.onAuthStateChanged(async (user) => {
        if (user) {
          if (
            to.matched.some(
              (record) => record.meta.requiresOrganizationId || record.meta.requiresSuperAdmin
            )
          ) {
            if (!store.state.userProfile?.id) {
              store.commit('SET_STATE_PROPERTY', {
                property: 'showPageSkeleton',
                value: true,
              });
              await store.dispatch('fetchUserProfile', user);
            }
            store.commit('SET_STATE_PROPERTY', {
              property: 'showPageSkeleton',
              value: false,
            });
            checkProperAuthorization(to, next);
          } else {
            next();
          }
        } else {
          next({
            path: 'login',
          });
        }
      });
    } else {
      next();
    }
  });
  return router;
}
