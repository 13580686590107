<template>
  <v-avatar :color="avatarColor" :size="size">
    <span :class="avatarClass">{{ initials }}</span>
  </v-avatar>
</template>
<script setup>
import { computed } from 'vue';
import { getColorFromHash } from '@/styles/stringToColor';

const props = defineProps({
  size: {
    type: String,
    default: 'small',
    validator: (value) => ['small', 'default'].includes(value),
  },
  initials: {
    type: String,
    default: '',
  },
  textForColor: {
    type: String,
    default: '',
  },
});

const avatarClass = computed(() => (props.size === 'default' ? 'medium-avatar' : 'small-avatar'));
const avatarColor = computed(() => getColorFromHash(props.textForColor));
</script>

<style lang="scss" scoped>
.medium-avatar {
  @include text18($font-weight: 400);
}
.small-avatar {
  @include text14($font-weight: 400);
}
</style>
